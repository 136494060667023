html,
body {
  height: 100%;
}
.parallax {
  height: 60%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax-text {
  background-color: #00000060;
  height: 100%;
}
.parallax-text {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
}

.bg-master-lightest {
  background-color: #fafafa;
}

#staffing .page-headings {
  margin-bottom: 5%;
  color: #685959;
}

#staffing .col-sm-6 {
  height: 80%;
  display: flex; /* This says treat this element like a table cell */
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: center;
}
/* ===================================================== */
.image-responsive-height {
  height: 80%;
  overflow: hidden;
}

.image-responsive-width {
  width: 80%;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-20 {
  padding-top: 20px !important;
}
.pl-10 {
  padding-left: 10px;
}
.bg-warning {
  background-color: #f8d053;
}

/* ============================================= */
.image-responsive-height-100 {
  height: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .image-responsive-height {
    height: 50%;
    overflow: hidden;
  }
  #staffing .col-sm-6 {
    height: auto;
  }
}
